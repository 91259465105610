import React from 'react'
import { Chessboard, getConfig } from '@pool/components/chessboard/Chessboard'
import { ukMap } from './ukMap'
import { x } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { useInView } from 'react-intersection-observer'
import { slideToId } from '@pool/utils/slideToId'

const config = getConfig({
  cell_height: 10,
  cell_width: 10,
  cell_amount_x: 42,
  cell_amount_y: 66,
  cell_color: '#73C4A8',
  stroke_width: 3,
  axis_x: false,
  axis_y: false
})

function radar(dots, centers, options) {
  let counter = 0
  return () => {
    ++counter

    if (counter === 50) {
      counter = 0
    }

    const _centers = centers.map((center) => ({
      ...center,
      radius: counter
    }))

    return updateWaves(dots, _centers, options)
  }
}

function updateWaves(dots, centers, options) {
  const { useGradient = true } = options

  const updatedDots = dots.map((dot) => {
    let totalOpacity = 0

    centers.forEach((center) => {
      const effectiveRadius = center.radius * 0.5
      const tolerance = center.tolerance
      const distance = Math.sqrt(
        (dot.x - center.x) ** 2 + (dot.y - center.y) ** 2
      )

      let localOpacity = 0

      if (useGradient) {
        if (
          distance >= effectiveRadius - tolerance &&
          distance <= effectiveRadius + tolerance
        ) {
          if (distance < effectiveRadius) {
            const innerFade =
              (distance - (effectiveRadius - tolerance)) / tolerance
            localOpacity = Math.max(localOpacity, innerFade)
          } else {
            const outerFade = 1 - (distance - effectiveRadius) / tolerance
            localOpacity = Math.max(localOpacity, outerFade)
          }
        }
      } else {
        if (
          distance >= effectiveRadius - tolerance &&
          distance <= effectiveRadius + tolerance
        ) {
          localOpacity = Math.max(localOpacity, 1)
        }
      }

      const centerDistance = Math.sqrt(
        (dot.x - center.x) ** 2 + (dot.y - center.y) ** 2
      )
      const fadeFactor = 1 - Math.min(centerDistance / center.fadeRange, 1) // Skala od [1, 0]
      localOpacity *= fadeFactor
      totalOpacity = Math.max(totalOpacity, localOpacity)

      if (dot.x === center.x && dot.y === center.y) {
        totalOpacity = 1
      }
    })

    return { ...dot, opacity: Math.min(Math.max(totalOpacity, 0), 1) }
  })

  return updatedDots
}

export function Explore() {
  const { ref, inView } = useInView({
    threshold: 0
  })

  const [coordinates, setCoordinates] = React.useState()
  const fpsInterval = React.useRef()
  const then = React.useRef()
  const startTime = React.useRef()
  const requestRef = React.useRef()
  const SPEED = 15

  const _radarAnimation = React.useMemo(() => {
    return radar(
      ukMap,
      [
        {
          x: 30,
          y: 51,
          radius: 0,
          tolerance: 2,
          fadeRange: 20
        },
        {
          x: 28,
          y: 39,
          radius: 0,
          tolerance: 2,
          fadeRange: 20
        }
      ],
      {
        useGradient: false
      }
    )
  }, [])

  React.useEffect(() => {
    function animate() {
      requestRef.current = requestAnimationFrame(animate)
      const now = Date.now()
      const elapsed = now - then.current

      if (elapsed > fpsInterval.current) {
        then.current = now - (elapsed % fpsInterval.current)
        setCoordinates(inView ? _radarAnimation() : null)
      }
    }

    function startAnimating(fps) {
      fpsInterval.current = 1000 / fps
      then.current = Date.now()
      startTime.current = then
      animate()
    }

    if (inView) {
      startAnimating(SPEED)
    }
    return () => cancelAnimationFrame(requestRef.current)
  }, [setCoordinates, inView, _radarAnimation])

  const waves = React.useMemo(() => {
    return (
      coordinates &&
      coordinates?.map(({ x, y, opacity }, index) => {
        return (
          <rect
            opacity={opacity}
            key={index}
            width={config.cell_width - config.stroke_width}
            height={config.cell_height - config.stroke_width}
            fill={'#E5FFC5'}
            style={{
              transformOrigin: `${
                config.cell_width / 2 + config.stroke_offset * 2
              }px ${config.cell_height / 2 + config.stroke_offset * 2}px`,
              transform: `translate(${
                x * config.cell_width + config.svg_offset + config.stroke_offset
              }px, ${
                y * config.cell_height +
                config.svg_offset +
                config.stroke_offset
              }px)`
            }}
          />
        )
      })
    )
  }, [coordinates])

  return (
    <>
      <x.section
        ref={ref}
        id="explore"
        mt={{ _: '75px', xl: '130px' }}
        pt={{ _: '75px', xl: '130px' }}
        w={{ _: '100%', maxWidth: 'midWidth' }}
        mx="auto"
        px={{ _: '30px', md: '60px' }}>
        <x.div display="flex" gap="144px">
          <x.div display={{ _: 'none', xl: 'block' }}>
            <Chessboard
              w={{ _: '400px', xl: 'auto' }}
              h={{ _: '400px', xl: 'auto' }}
              config={config}
              coordinates={ukMap}
              addon={waves}
            />
          </x.div>
          <x.div>
            <x.h3
              fontSize="20px"
              fontWeight="600"
              color="lightGreen"
              mb="15px"
              lineHeight="100%">
              Explore our Data Centers
            </x.h3>
            <x.h2
              fontSize={{ _: '24px', xl: '30px' }}
              fontWeight="600"
              color="lime"
              lineHeight="130%"
              mb="30px">
              Two Tier 4 Data Centers in the UK
            </x.h2>
            <x.p
              maxWidth="560px"
              fontSize="16px"
              color="lime"
              lineHeight="200%">
              Operating within two Tier 4 data centers is critical to ensuring
              scale and redundancy. By working closely with our Data Center
              partner, we have unmatched scaling capabilities with class-leading
              performance.
            </x.p>

            <x.div
              mt="70px"
              mb="50px"
              display="grid"
              gridTemplateColumns={{ _: 1, sm: 2 }}
              gap="35px">
              <x.article
                bg="lime"
                px="35px"
                pb="35px"
                pt="95px"
                borderRadius="5px">
                <x.h1 fontWeight="600" fontSize="20px" color="forest" mb="34px">
                  Yorkshire
                </x.h1>
                <x.p
                  fontSize="14px"
                  fontWeight="500"
                  color="blackGreen"
                  lineHeight="200%">
                  Residing in the north of England, the Yorkshire data centre
                  provides significant edge connectivity with 1 Megawatt of
                  power.
                </x.p>
              </x.article>
              <x.article
                bg="lime"
                px="35px"
                pb="35px"
                pt="95px"
                borderRadius="5px">
                <x.h1 fontWeight="600" fontSize="20px" color="forest" mb="34px">
                  Milton Keynes
                </x.h1>
                <x.p
                  fontSize="14px"
                  fontWeight="500"
                  color="blackGreen"
                  lineHeight="200%">
                  Within the key industrial city in England, Milton Keynes is a
                  world-leading data center with a multi-carrier high-speed
                  network powered by 100% renewable energy.
                </x.p>
              </x.article>
            </x.div>
            <Button
              onClick={() => slideToId('footer')}
              variant="vandog"
              size="md"
              fontWeight="600">
              Get A Quote
            </Button>
          </x.div>
        </x.div>
      </x.section>
    </>
  )
}
