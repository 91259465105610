import React from 'react'
import { x } from '@xstyled/styled-components'
import { Navbar } from '@pool/components/navbar/Navbar'
import { Hero } from '@pool/components/hero/Hero'
import { Solutions } from '@pool/components/solutions/Solutions'
import { Explore } from '@pool/components/explore/Explore'
import { Footer } from '@pool/components/footer/Footer'
import { Specs } from '@pool/components/specs/Specs'
import { B2b } from '@pool/components/b2b/B2b'
import { Provider } from '@pool/components/provider/Provider'
import { Logos } from '@pool/components/logos/Logos'

export function LandingPage() {
  return (
    <x.main>
      <Navbar />
      <Hero />
      <Solutions />
      <Explore />
      <Specs />
      <B2b />
      <Provider />
      <Logos />
      <Footer />
    </x.main>
  )
}
