import React from 'react'
import { x } from '@xstyled/styled-components'
import { ButtonUnstyled } from '@pool/components/button/Button'
import { Button } from '@pool/components/button/Button'
import { slideToId } from '@pool/utils/slideToId'

const list = [
  {
    q: 'Custom Solutions',
    a: "Due to the nature of LinkPool's infrastructure, we offer highly tailored solutions at a significant scale. Whether that's customised deployments or tailored whole managed infrastructure offerings with separate racks and networks."
  },
  {
    q: 'High-Volume Blockchain Endpoints',
    a: 'We provide high-throughput un-metered endpoints to large clients within the Web3 industry. From shared clusters to completely dedicated endpoints, demand can be met with low-latency and class-leading resiliency at market leading costs.'
  },
  {
    q: 'High-Value Validators',
    a: 'LinkPool secures $100M+ of assets throughout the various networks that it operates on. We continuously expand our validating operation to boost decentralisation and security within the Web3 industry.'
  },
  {
    q: 'DeFi Development',
    a: 'LinkPool built and is the core contributor to stake.link, a leading DeFi protocol and first-of-its-kind liquid staking initiative for the Chainlink network. Significant work can be undertaken ranging from project inception to full-stack maintenance.'
  },
  {
    q: 'Early Stage Investment',
    a: "We believe in the early Blockchain projects we help bootstrap, that's why LinkPool operates an early stage investment fund to invest into the projects it aids development in."
  }
]

export function B2b() {
  const [active, setActive] = React.useState(null)
  function handleStep(index) {
    setActive(index === active ? null : index)
  }

  return (
    <x.section
      id="b2b"
      mt={{ _: '75px', xl: '130px' }}
      pt={{ _: '75px', xl: '130px' }}
      w={{ _: '100%', maxWidth: 'midWidth' }}
      mx="auto"
      px={{ _: '30px', md: '60px' }}>
      <x.div
        display="flex"
        gap={{ _: '50px', xl: '144px' }}
        justifyContent="space-between"
        flexDirection={{ _: 'column', xl: 'row' }}>
        <x.div>
          <x.h3
            fontSize="20px"
            fontWeight="600"
            color="lightGreen"
            mb="15px"
            lineHeight="100%">
            Use a Reliable Web3 Provider
          </x.h3>
          <x.h2
            fontSize="30px"
            fontWeight="600"
            color="lime"
            lineHeight="150%"
            mb="30px"
            maxWidth="400px">
            Discover The Power of Our Solutions
          </x.h2>
          <x.p maxWidth="560px" fontSize="16px" color="lime" lineHeight="200%">
            We offer a wide array of services that range from ultra-reliable
            blockchain endpoints to fully tailored infrastructure solutions to
            meet any requirement.
          </x.p>
        </x.div>
        <x.div w={{ _: '100%', xl: '767px' }}>
          <x.ul border="default" borderBottomColor="white-a20" mb="30px">
            {list.map((item, i) => {
              return (
                <x.li key={item.q}>
                  <ButtonUnstyled
                    w="100%"
                    border="default"
                    borderTopColor="white-a20"
                    display="flex"
                    onClick={() => handleStep(i)}
                    py="30px">
                    <x.p
                      color="lime"
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight={{ _: '130%', lg: '100%' }}
                      flexGrow="1">
                      {item.q}
                    </x.p>
                    <x.span
                      color="lime"
                      display="flex"
                      alignItems="center"
                      pr="35px"
                      ml="20px"
                      h="16px">
                      <x.div transform rotate={i === active ? 180 : 0}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="22"
                          viewBox="0 0 20 22"
                          fill="none">
                          <path
                            d="M10.4968 1.14575C10.4968 0.869608 10.273 0.64575 9.99682 0.645751C9.72068 0.645751 9.49682 0.869608 9.49682 1.14575L10.4968 1.14575ZM9.49682 1.14575L9.49682 21.1458L10.4968 21.1458L10.4968 1.14575L9.49682 1.14575Z"
                            fill="#E5FFC5"
                          />
                          <path
                            d="M1.47068 12.7013L9.99658 21.1458L18.5225 12.7013"
                            stroke="#E5FFC5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </x.div>
                    </x.span>
                  </ButtonUnstyled>
                  <x.div
                    display={i === active ? 'block' : 'none'}
                    mt={{ _: '0px', lg: '30x' }}
                    mb="30px">
                    <x.p
                      color="lime"
                      opacity="0.7"
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="195%">
                      {item.a}
                    </x.p>
                  </x.div>
                </x.li>
              )
            })}
          </x.ul>
          <Button
            onClick={() => slideToId('footer')}
            variant="vandog"
            size="md"
            fontWeight="600">
            Get a Quote
          </Button>
        </x.div>
      </x.div>
    </x.section>
  )
}
