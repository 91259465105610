import React from 'react'
import { x, useUp, useBreakpoint } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { slideToId } from '@pool/utils/slideToId'
import {
  Chessboard,
  getConfig,
  generateRandomPawns
} from '@pool/components/chessboard/Chessboard'
import { useInView } from 'react-intersection-observer'

const SPEED = 2

export function Hero() {
  const isMd = useUp('md')
  const breakpoint = useBreakpoint()

  const { ref, inView } = useInView({
    threshold: 0
  })

  const [coordinates, setCoordinates] = React.useState()
  const fpsInterval = React.useRef()
  const then = React.useRef()
  const startTime = React.useRef()
  const requestRef = React.useRef()

  const config = React.useMemo(() => {
    const config = getConfig({
      cell_height: 30,
      cell_width: 20,
      cell_amount_x: ['maxWidth', 'midWidth', 'xl'].includes(breakpoint)
        ? 81
        : ['lg', 'md'].includes(breakpoint)
        ? 50
        : 20,
      cell_amount_y: ['maxWidth', 'midWidth', 'xl'].includes(breakpoint)
        ? 25
        : ['lg', 'md'].includes(breakpoint)
        ? 16
        : 16
    })

    const pawnsConfig = {
      total: 50,
      exclude_x: ['maxWidth', 'midWidth', 'xl'].includes(breakpoint)
        ? [7, 50]
        : [],
      exclude_y: ['maxWidth', 'midWidth', 'xl'].includes(breakpoint)
        ? [4, 14]
        : [],
      opacity: ['maxWidth', 'midWidth', 'xl', 'lg'].includes(breakpoint)
        ? 0.7
        : 0.3,
      ...config
    }

    return {
      grid: config,
      pawns: pawnsConfig
    }
  }, [breakpoint])

  React.useEffect(() => {
    function animate() {
      requestRef.current = requestAnimationFrame(animate)
      const now = Date.now()
      const elapsed = now - then.current
      if (elapsed > fpsInterval.current) {
        then.current = now - (elapsed % fpsInterval.current)
        setCoordinates(generateRandomPawns(config.pawns))
      }
    }

    function startAnimating(fps) {
      fpsInterval.current = 1000 / fps
      then.current = Date.now()
      startTime.current = then
      animate()
    }
    if (inView) {
      startAnimating(SPEED)
    }
    return () => cancelAnimationFrame(requestRef.current)
  }, [inView, config?.pawns])

  return (
    <x.section
      ref={ref}
      w={{ _: '100%', maxWidth: 'maxWidth' }}
      mx="auto"
      my="25px"
      px={{ _: '30px', md: '60px' }}
      position="relative">
      <x.div position="relative">
        <x.div
          position="absolute"
          left={{ _: '40px', sm: '50px', lg: '176px', '2xl': '176px' }}
          top={{
            _: '40px',
            sm: '60px',
            lg: '80px',
            xl: '115px',
            '2xl': '165px'
          }}>
          <x.h1
            fontSize={{ _: '30px', sm: '50px', lg: '60px', '2xl': '81px' }}
            fontWeight="600"
            color="lime"
            lineHeight={{ _: '130%', md: '111%' }}>
            {isMd ? (
              <>
                Web3 <br />
                Infrastructure at Scale
              </>
            ) : (
              <>
                Web3 <br />
                Infrastructure at Scale
              </>
            )}
          </x.h1>
          <Button
            mt="17px"
            variant="vandog"
            size="md"
            fontWeight="600"
            onClick={() => slideToId('solutions')}>
            Get Started
          </Button>
        </x.div>
      </x.div>

      <x.div display="flex" justifyContent="center">
        <Chessboard
          width="100%"
          height="auto"
          config={config.grid}
          coordinates={coordinates || generateRandomPawns(config?.pawns) || []}
        />
      </x.div>
    </x.section>
  )
}
