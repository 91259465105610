import React from 'react'
import { x } from '@xstyled/styled-components'

export function generateRandomPawns({
  cell_amount_x,
  cell_amount_y,
  total,
  exclude_x,
  exclude_y,
  opacity
}) {
  const array = []
  while (array.length < total) {
    const x = Math.floor(Math.random() * cell_amount_x)
    const y = Math.floor(Math.random() * cell_amount_y)
    if (
      x > (exclude_x?.[0] || 0) &&
      x < (exclude_x?.[1] || 9) &&
      y > (exclude_y?.[0] || 9) &&
      y < (exclude_y?.[1] || 9)
    ) {
      continue
    }
    array.push({ x, y, opacity })
  }
  return array
}

export function getConfig({
  cell_height,
  cell_width,
  cell_amount_x,
  cell_amount_y,
  axis_x,
  axis_y,
  cell_color,
  stroke_width,
  stroke_color
}) {
  const _stroke_width = stroke_width ?? 1
  const stroke_offset = _stroke_width / 2
  const svg_offset = _stroke_width
  const cell_offset = 1

  return {
    stroke_width: _stroke_width,
    stroke_offset,
    svg_width: cell_width * cell_amount_x + svg_offset * 2,
    svg_height: cell_height * cell_amount_y + svg_offset * 2,
    svg_offset,
    cell_height,
    cell_width,
    cell_amount_x,
    cell_amount_y,
    cell_offset,
    axis_x: axis_x ?? true,
    axis_y: axis_y ?? true,
    cell_color,
    stroke_color
  }
}

export function Chessboard({
  width,
  height,
  config,
  coordinates,
  addon,
  ...props
}) {
  return (
    <x.svg
      width={width || config.svg_width}
      height={height || config.svg_height}
      viewBox={`0 0 ${config.svg_width} ${config.svg_height}`}
      {...props}>
      <g>
        {config.axis_x &&
          Array.from({ length: config.cell_amount_y + 1 }, (_, index) => {
            return (
              <line
                opacity="0.3"
                x1={config.svg_offset}
                y1={index * config.cell_height + config.svg_offset}
                x2={config.svg_width - config.svg_offset - 1}
                y2={index * config.cell_height + config.svg_offset}
                key={index}
                strokeWidth={config.stroke_width}
                stroke={config.stroke_color || '#62856C'}></line>
            )
          })}
        {config.axis_y &&
          Array.from({ length: config.cell_amount_x + 1 }, (_, index) => {
            return (
              <line
                opacity="0.3"
                x1={index * config.cell_width + config.svg_offset}
                y1={config.svg_offset}
                x2={index * config.cell_width + config.svg_offset}
                y2={config.svg_height - config.svg_offset - 1}
                key={index}
                strokeWidth={config.stroke_width}
                stroke={config.stroke_color || '#62856C'}></line>
            )
          })}

        {coordinates &&
          coordinates?.map(
            ({ x, y, opacity, animationClass, delay }, index) => {
              return (
                <rect
                  opacity={opacity}
                  key={index}
                  className={animationClass}
                  width={config.cell_width - config.stroke_width}
                  height={config.cell_height - config.stroke_width}
                  fill={config.cell_color || '#62856C'}
                  style={{
                    animationDelay: `${delay}s`, // Dodanie opóźnienia
                    transformOrigin: `${
                      config.cell_width / 2 + config.stroke_offset * 2
                    }px ${config.cell_height / 2 + config.stroke_offset * 2}px`,
                    transform: `translate(${
                      x * config.cell_width +
                      config.svg_offset +
                      config.stroke_offset
                    }px, ${
                      y * config.cell_height +
                      config.svg_offset +
                      config.stroke_offset
                    }px)`
                  }}

                  // style={{
                  //   transformOrigin: `${
                  //     config.cell_width / 2 + config.stroke_offset * 2
                  //   }px ${config.cell_height / 2 + config.stroke_offset * 2}px`,
                  //   transform: `translate(${
                  //     x * config.cell_width +
                  //     config.svg_offset +
                  //     config.stroke_offset
                  //   }px, ${
                  //     y * config.cell_height +
                  //     config.svg_offset +
                  //     config.stroke_offset
                  //   }px)`
                  // }}
                />
              )
            }
          )}
      </g>
      <g>{addon}</g>
    </x.svg>
  )
}
