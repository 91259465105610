import React from 'react'
import { x } from '@xstyled/styled-components'
import { Chessboard, getConfig } from '@pool/components/chessboard/Chessboard'
import { useInView } from 'react-intersection-observer'

const config = getConfig({
  cell_height: 30,
  cell_width: 20,
  cell_amount_x: 19,
  cell_amount_y: 13,
  cell_color: '#093331',
  stroke_width: 1,
  axis_x: false,
  axis_y: false,
  viewBox: false
})

function createAndModifyGrid() {
  const grid = []
  for (let x = 0; x < 19; x++) {
    for (let y = 0; y < 13; y++) {
      grid.push({ x, y })
    }
  }

  const elementsToRemove = Math.floor(grid.length * 0.1)

  for (let i = 0; i < elementsToRemove; i++) {
    const randomIndex = Math.floor(Math.random() * grid.length)
    grid.splice(randomIndex, 1)
  }

  return grid
}

const coordinatesDefault = createAndModifyGrid()

function shiftCoordinatesY(coordinates, maxY) {
  let updatedCoordinates = coordinates.map(({ x, y }) => {
    y += 1
    if (y > maxY) {
      y = 0
    }
    return { x, y }
  })

  if (updatedCoordinates.length > 40) {
    const randomIndex = Math.floor(Math.random() * updatedCoordinates.length)
    updatedCoordinates.splice(randomIndex, 1)
  }

  return updatedCoordinates
}

export const MovingCard = () => {
  const { ref, inView } = useInView({
    threshold: 0
  })

  const [coordinates, setCoordinates] = React.useState(coordinatesDefault)
  const fpsInterval = React.useRef()
  const then = React.useRef()
  const startTime = React.useRef()
  const requestRef = React.useRef()
  const _coordinates = React.useRef(coordinatesDefault)

  const SPEED = 5

  React.useEffect(() => {
    function animate() {
      requestRef.current = requestAnimationFrame(animate)
      const now = Date.now()
      const elapsed = now - then.current

      if (elapsed > fpsInterval.current) {
        then.current = now - (elapsed % fpsInterval.current)
        _coordinates.current = shiftCoordinatesY(_coordinates.current, 12)
        setCoordinates(inView ? _coordinates.current : null)
      }
    }

    function startAnimating(fps) {
      fpsInterval.current = 1000 / fps
      then.current = Date.now()
      startTime.current = then
      animate()
    }

    if (inView) {
      startAnimating(SPEED)
    }
    return () => cancelAnimationFrame(requestRef.current)
  }, [setCoordinates, inView])

  return (
    <x.article h="100%" bg="grassGreen" ref={ref}>
      <x.div bg="lime" h={{ _: '270px', xl: '390px' }} overflow="hidden">
        <Chessboard
          position="relative"
          top="-1px"
          left="1px"
          config={config}
          coordinates={coordinates}
          width="100%"
          preserveAspectRatio="none"
        />
      </x.div>
      <x.div h="195px" display="flex">
        <x.div mt="auto" p="38px">
          <x.p fontSize="16px" fontWeight="400" color="blackGreen" mb="10px">
            Flash Storage
          </x.p>
          <x.h1
            fontSize="55px"
            fontWeight="600"
            lineHeight="110%"
            color="blackGreen">
            1 PB
          </x.h1>
        </x.div>
      </x.div>
    </x.article>
  )
}
