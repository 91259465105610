import React from 'react'
import styled, { x, useUp } from '@xstyled/styled-components'
import { useForm, ValidationError } from '@formspree/react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Button } from '@pool/components/button/Button'
import { ArrowRightLong } from '@pool/components/assets/ArrowRightLong'

const captchaKey = '6LfWDxkoAAAAAOpUq2zmXy1nJGcTmvOJ3LshWkyW'
const formspreeKey = 'xgejyrev'

export function ContactForm() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
      <Contact />
    </GoogleReCaptchaProvider>
  )
}

export const Label = styled.labelBox`
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: #62856c;
  display: block;
  width: 100%;
`

export const Input = styled.inputBox`
  font-size: 16px;
  font-weight: 400;
  line-height: 250%;
  color: #62856c;
  display: block;
  background: transparent;
  border-color: #62856c;
  border-width: 1px;
  width: 100%;
  padding: 5px 15px;
  border-radius: 5px;

  &::placeholder {
    color: #62856c;
    opacity: 1;
  }

  &:focus {
    border-color: lime;
    color: lime;
    outline: none;
  }

  &:focus::placeholder {
    color: lime;
  }
`
export const Textarea = styled.textareaBox`
  font-size: 16px;
  font-weight: 400;
  line-height: 250%;
  color: #62856c;
  display: block;
  background: transparent;
  border-color: #62856c;
  border-width: 2px;
  width: 100%;
  padding: 5px 15px;
  border-radius: 5px;

  &::placeholder {
    color: #62856c;
    opacity: 1;
  }

  &:focus {
    border-color: lime;
    color: lime;
    outline: none;
  }

  &:focus::placeholder {
    color: lime;
  }
`

export function Contact() {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [state, handleSubmit] = useForm(formspreeKey, {
    data: { 'g-recaptcha-response': executeRecaptcha }
  })
  const upLg = useUp('lg')

  React.useEffect(() => {
    if (state.succeeded) {
      const name = document.getElementById('name')
      const email = document.getElementById('email')
      const company = document.getElementById('company')
      const message = document.getElementById('message')
      name.value = ''
      email.value = ''
      company.value = ''
      message.value = ''
    }
  }, [state.succeeded])

  return (
    <x.form onSubmit={handleSubmit} minWidth="100%" display="block">
      <x.div spaceY="20px">
        <Input id="name" name="name" placeholder="Full name" required={true} />
        <Input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          required={true}
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <Input id="company" name="company" placeholder="Company name" />
        <Textarea
          rows="3"
          id="message"
          name="message"
          placeholder="Enter message"
          required={true}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </x.div>
      <x.div
        mt="70px"
        display="flex"
        alignItems={{ _: 'inherit', lg: 'center' }}
        spaceX={{ _: '0px', lg: '30px' }}
        spaceY={{ _: '30px', lg: '0px' }}
        flexDirection={{ _: 'column', lg: 'row' }}>
        <Button
          textAlign="right"
          type="submit"
          disabled={state.submitting}
          display="flex"
          spaceX={{ _: '50px' }}
          py={{ _: '12px', lg: '0' }}
          px={{ _: '50px' }}
          fontWeight="600"
          fontSize={{ _: '20px' }}>
          <x.span>Submit</x.span>
          {upLg && (
            <>
              <x.span
                border="default"
                borderColor="inherit"
                w="2px"
                h={{ _: '46px' }}
              />
              <ArrowRightLong />
            </>
          )}
        </Button>
        {state.succeeded && (
          <x.p
            color="forest"
            fontSize="16px"
            fontWeight="400"
            lineHeight="100%">
            Thank you for submition!
          </x.p>
        )}
      </x.div>
    </x.form>
  )
}
