import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Button as AriaButton } from '@ariakit/react'

const buttonBaseStyle = {
  position: 'relative',
  display: ' inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  textAlign: 'center',
  cursor: 'pointer',
  borderWidth: '2',
  borderStyle: 'solid',
  lineHeight: 1,
  outline: { focus: 'none' },
  borderRadius: '5px',
  opacity: { _: 1, disabled: 0.5 },
  appearance: 'none',
  overflow: 'hidden'
}

const styles = () => {
  return {
    vandog: {
      color: {
        _: 'lime',
        hover: 'grassGreen',
        focus: 'grassGreen'
      },
      backgroundColor: {
        _: 'grassGreen',
        hover: 'lime',
        focus: 'lime'
      },
      borderColor: {
        _: 'grassGreen',
        hover: 'lime',
        focus: 'lime'
      }
    },
    default: {
      color: {
        _: 'lime',
        hover: 'white',
        focus: 'white'
      },
      background: 'transparent',
      borderColor: {
        _: 'lime',
        hover: 'white',
        focus: 'white'
      }
    }
  }
}

const sizes = () => {
  return {
    default: {
      px: '15px',
      py: '10px',
      fontSize: '16px'
    },
    sm: {
      px: '15px',
      py: '10px',
      fontSize: '16px'
    },
    md: {
      px: '25px',
      py: '15px',
      fontSize: '16px'
    }
  }
}
export const Button = React.forwardRef(function Button(
  { variant = 'default', size = 'default', children, ...props },
  ref
) {
  let buttonStyle = styles()[variant] || styles()['default']

  let buttonSize = sizes()[size] || sizes()['default']

  return (
    <x.button
      ref={ref}
      as={AriaButton}
      {...buttonBaseStyle}
      {...buttonStyle}
      {...buttonSize}
      {...props}>
      {children}
    </x.button>
  )
})

export const ButtonUnstyled = styled.buttonBox`
  border-radius: 0;
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
`
