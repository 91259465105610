export const ukMap = [
  {
    x: 19,
    y: 0
  },
  {
    x: 20,
    y: 0
  },
  {
    x: 21,
    y: 0
  },
  {
    x: 22,
    y: 0
  },
  {
    x: 19,
    y: 1
  },
  {
    x: 20,
    y: 1
  },
  {
    x: 21,
    y: 1
  },
  {
    x: 19,
    y: 2
  },
  {
    x: 20,
    y: 2
  },
  {
    x: 12,
    y: 4
  },
  {
    x: 13,
    y: 4
  },
  {
    x: 14,
    y: 4
  },
  {
    x: 16,
    y: 4
  },
  {
    x: 17,
    y: 4
  },
  {
    x: 18,
    y: 4
  },
  {
    x: 19,
    y: 4
  },
  {
    x: 20,
    y: 4
  },
  {
    x: 8,
    y: 5
  },
  {
    x: 9,
    y: 5
  },
  {
    x: 12,
    y: 5
  },
  {
    x: 13,
    y: 5
  },
  {
    x: 14,
    y: 5
  },
  {
    x: 15,
    y: 5
  },
  {
    x: 16,
    y: 6
  },
  {
    x: 17,
    y: 5
  },
  {
    x: 18,
    y: 5
  },
  {
    x: 19,
    y: 5
  },
  {
    x: 20,
    y: 5
  },
  {
    x: 6,
    y: 6
  },
  {
    x: 7,
    y: 6
  },
  {
    x: 8,
    y: 6
  },
  {
    x: 12,
    y: 6
  },
  {
    x: 13,
    y: 6
  },
  {
    x: 14,
    y: 6
  },
  {
    x: 15,
    y: 6
  },
  {
    x: 17,
    y: 6
  },
  {
    x: 18,
    y: 6
  },
  {
    x: 19,
    y: 6
  },
  {
    x: 5,
    y: 7
  },
  {
    x: 6,
    y: 7
  },
  {
    x: 7,
    y: 7
  },
  {
    x: 8,
    y: 7
  },
  {
    x: 11,
    y: 7
  },
  {
    x: 12,
    y: 7
  },
  {
    x: 13,
    y: 7
  },
  {
    x: 14,
    y: 7
  },
  {
    x: 15,
    y: 7
  },
  {
    x: 16,
    y: 7
  },
  {
    x: 17,
    y: 7
  },
  {
    x: 18,
    y: 7
  },
  {
    x: 5,
    y: 8
  },
  {
    x: 6,
    y: 8
  },
  {
    x: 7,
    y: 8
  },
  {
    x: 8,
    y: 8
  },
  {
    x: 11,
    y: 8
  },
  {
    x: 12,
    y: 8
  },
  {
    x: 13,
    y: 8
  },
  {
    x: 14,
    y: 8
  },
  {
    x: 15,
    y: 8
  },
  {
    x: 16,
    y: 8
  },
  {
    x: 17,
    y: 8
  },
  {
    x: 6,
    y: 9
  },
  {
    x: 7,
    y: 9
  },
  {
    x: 10,
    y: 9
  },
  {
    x: 11,
    y: 9
  },
  {
    x: 12,
    y: 9
  },
  {
    x: 13,
    y: 9
  },
  {
    x: 14,
    y: 9
  },
  {
    x: 15,
    y: 9
  },
  {
    x: 16,
    y: 9
  },
  {
    x: 17,
    y: 9
  },
  {
    x: 6,
    y: 10
  },
  {
    x: 10,
    y: 10
  },
  {
    x: 11,
    y: 10
  },
  {
    x: 12,
    y: 10
  },
  {
    x: 13,
    y: 10
  },
  {
    x: 14,
    y: 10
  },
  {
    x: 15,
    y: 10
  },
  {
    x: 16,
    y: 10
  },
  {
    x: 19,
    y: 10
  },
  {
    x: 21,
    y: 10
  },
  {
    x: 24,
    y: 10
  },
  {
    x: 4,
    y: 11
  },
  {
    x: 5,
    y: 11
  },
  {
    x: 7,
    y: 11
  },
  {
    x: 8,
    y: 11
  },
  {
    x: 11,
    y: 11
  },
  {
    x: 12,
    y: 11
  },
  {
    x: 13,
    y: 11
  },
  {
    x: 14,
    y: 11
  },
  {
    x: 15,
    y: 11
  },
  {
    x: 16,
    y: 11
  },
  {
    x: 17,
    y: 11
  },
  {
    x: 18,
    y: 11
  },
  {
    x: 19,
    y: 11
  },
  {
    x: 20,
    y: 11
  },
  {
    x: 21,
    y: 11
  },
  {
    x: 22,
    y: 11
  },
  {
    x: 23,
    y: 11
  },
  {
    x: 24,
    y: 11
  },
  {
    x: 25,
    y: 11
  },
  {
    x: 4,
    y: 12
  },
  {
    x: 7,
    y: 12
  },
  {
    x: 8,
    y: 12
  },
  {
    x: 9,
    y: 12
  },
  {
    x: 10,
    y: 12
  },
  {
    x: 11,
    y: 12
  },
  {
    x: 12,
    y: 12
  },
  {
    x: 13,
    y: 12
  },
  {
    x: 14,
    y: 12
  },
  {
    x: 15,
    y: 12
  },
  {
    x: 16,
    y: 12
  },
  {
    x: 17,
    y: 12
  },
  {
    x: 18,
    y: 12
  },
  {
    x: 19,
    y: 12
  },
  {
    x: 20,
    y: 12
  },
  {
    x: 21,
    y: 12
  },
  {
    x: 22,
    y: 12
  },
  {
    x: 23,
    y: 12
  },
  {
    x: 24,
    y: 12
  },
  {
    x: 25,
    y: 12
  },
  {
    x: 0,
    y: 33
  },
  {
    x: 1,
    y: 31
  },
  {
    x: 1,
    y: 33
  },
  {
    x: 1,
    y: 34
  },
  {
    x: 1,
    y: 35
  },
  {
    x: 2,
    y: 31
  },
  {
    x: 2,
    y: 32
  },
  {
    x: 2,
    y: 33
  },
  {
    x: 2,
    y: 34
  },
  {
    x: 2,
    y: 35
  },
  {
    x: 3,
    y: 15
  },
  {
    x: 3,
    y: 29
  },
  {
    x: 3,
    y: 30
  },
  {
    x: 3,
    y: 31
  },
  {
    x: 3,
    y: 32
  },
  {
    x: 3,
    y: 33
  },
  {
    x: 3,
    y: 34
  },
  {
    x: 3,
    y: 35
  },
  {
    x: 4,
    y: 13
  },
  {
    x: 4,
    y: 14
  },
  {
    x: 4,
    y: 15
  },
  {
    x: 4,
    y: 29
  },
  {
    x: 4,
    y: 30
  },
  {
    x: 4,
    y: 31
  },
  {
    x: 4,
    y: 32
  },
  {
    x: 4,
    y: 33
  },
  {
    x: 4,
    y: 34
  },
  {
    x: 4,
    y: 35
  },
  {
    x: 5,
    y: 19
  },
  {
    x: 5,
    y: 28
  },
  {
    x: 5,
    y: 29
  },
  {
    x: 5,
    y: 30
  },
  {
    x: 5,
    y: 31
  },
  {
    x: 5,
    y: 32
  },
  {
    x: 5,
    y: 33
  },
  {
    x: 6,
    y: 19
  },
  {
    x: 6,
    y: 28
  },
  {
    x: 6,
    y: 29
  },
  {
    x: 6,
    y: 30
  },
  {
    x: 6,
    y: 31
  },
  {
    x: 6,
    y: 32
  },
  {
    x: 6,
    y: 33
  },
  {
    x: 6,
    y: 34
  },
  {
    x: 6,
    y: 35
  },
  {
    x: 6,
    y: 36
  },
  {
    x: 7,
    y: 13
  },
  {
    x: 7,
    y: 18
  },
  {
    x: 7,
    y: 24
  },
  {
    x: 7,
    y: 25
  },
  {
    x: 7,
    y: 28
  },
  {
    x: 7,
    y: 29
  },
  {
    x: 7,
    y: 30
  },
  {
    x: 7,
    y: 31
  },
  {
    x: 7,
    y: 32
  },
  {
    x: 7,
    y: 33
  },
  {
    x: 7,
    y: 34
  },
  {
    x: 7,
    y: 35
  },
  {
    x: 7,
    y: 36
  },
  {
    x: 8,
    y: 13
  },
  {
    x: 8,
    y: 14
  },
  {
    x: 8,
    y: 15
  },
  {
    x: 8,
    y: 16
  },
  {
    x: 8,
    y: 18
  },
  {
    x: 8,
    y: 19
  },
  {
    x: 8,
    y: 20
  },
  {
    x: 8,
    y: 21
  },
  {
    x: 8,
    y: 24
  },
  {
    x: 8,
    y: 25
  },
  {
    x: 8,
    y: 26
  },
  {
    x: 8,
    y: 28
  },
  {
    x: 8,
    y: 29
  },
  {
    x: 8,
    y: 30
  },
  {
    x: 8,
    y: 31
  },
  {
    x: 8,
    y: 32
  },
  {
    x: 8,
    y: 33
  },
  {
    x: 8,
    y: 34
  },
  {
    x: 8,
    y: 35
  },
  {
    x: 9,
    y: 13
  },
  {
    x: 9,
    y: 14
  },
  {
    x: 9,
    y: 17
  },
  {
    x: 9,
    y: 18
  },
  {
    x: 9,
    y: 19
  },
  {
    x: 9,
    y: 20
  },
  {
    x: 9,
    y: 23
  },
  {
    x: 9,
    y: 24
  },
  {
    x: 9,
    y: 25
  },
  {
    x: 9,
    y: 28
  },
  {
    x: 9,
    y: 29
  },
  {
    x: 9,
    y: 30
  },
  {
    x: 9,
    y: 31
  },
  {
    x: 9,
    y: 32
  },
  {
    x: 9,
    y: 33
  },
  {
    x: 9,
    y: 34
  },
  {
    x: 9,
    y: 35
  },
  {
    x: 9,
    y: 36
  },
  {
    x: 10,
    y: 13
  },
  {
    x: 10,
    y: 14
  },
  {
    x: 10,
    y: 15
  },
  {
    x: 10,
    y: 17
  },
  {
    x: 10,
    y: 18
  },
  {
    x: 10,
    y: 19
  },
  {
    x: 10,
    y: 20
  },
  {
    x: 10,
    y: 22
  },
  {
    x: 10,
    y: 23
  },
  {
    x: 10,
    y: 26
  },
  {
    x: 10,
    y: 27
  },
  {
    x: 10,
    y: 30
  },
  {
    x: 10,
    y: 31
  },
  {
    x: 10,
    y: 32
  },
  {
    x: 10,
    y: 33
  },
  {
    x: 10,
    y: 34
  },
  {
    x: 11,
    y: 13
  },
  {
    x: 11,
    y: 14
  },
  {
    x: 11,
    y: 15
  },
  {
    x: 11,
    y: 16
  },
  {
    x: 11,
    y: 17
  },
  {
    x: 11,
    y: 18
  },
  {
    x: 11,
    y: 19
  },
  {
    x: 11,
    y: 20
  },
  {
    x: 11,
    y: 21
  },
  {
    x: 11,
    y: 22
  },
  {
    x: 11,
    y: 23
  },
  {
    x: 11,
    y: 24
  },
  {
    x: 11,
    y: 25
  },
  {
    x: 11,
    y: 26
  },
  {
    x: 11,
    y: 27
  },
  {
    x: 11,
    y: 32
  },
  {
    x: 11,
    y: 33
  },
  {
    x: 11,
    y: 34
  },
  {
    x: 12,
    y: 13
  },
  {
    x: 12,
    y: 14
  },
  {
    x: 12,
    y: 15
  },
  {
    x: 12,
    y: 16
  },
  {
    x: 12,
    y: 17
  },
  {
    x: 12,
    y: 18
  },
  {
    x: 12,
    y: 19
  },
  {
    x: 12,
    y: 20
  },
  {
    x: 12,
    y: 21
  },
  {
    x: 12,
    y: 22
  },
  {
    x: 12,
    y: 23
  },
  {
    x: 12,
    y: 27
  },
  {
    x: 12,
    y: 28
  },
  {
    x: 12,
    y: 29
  },
  {
    x: 12,
    y: 30
  },
  {
    x: 12,
    y: 31
  },
  {
    x: 13,
    y: 13
  },
  {
    x: 13,
    y: 14
  },
  {
    x: 13,
    y: 15
  },
  {
    x: 13,
    y: 16
  },
  {
    x: 13,
    y: 17
  },
  {
    x: 13,
    y: 18
  },
  {
    x: 13,
    y: 19
  },
  {
    x: 13,
    y: 20
  },
  {
    x: 13,
    y: 21
  },
  {
    x: 13,
    y: 22
  },
  {
    x: 13,
    y: 23
  },
  {
    x: 13,
    y: 24
  },
  {
    x: 13,
    y: 25
  },
  {
    x: 13,
    y: 28
  },
  {
    x: 13,
    y: 29
  },
  {
    x: 13,
    y: 30
  },
  {
    x: 13,
    y: 32
  },
  {
    x: 14,
    y: 13
  },
  {
    x: 14,
    y: 14
  },
  {
    x: 14,
    y: 15
  },
  {
    x: 14,
    y: 16
  },
  {
    x: 14,
    y: 17
  },
  {
    x: 14,
    y: 18
  },
  {
    x: 14,
    y: 19
  },
  {
    x: 14,
    y: 20
  },
  {
    x: 14,
    y: 21
  },
  {
    x: 14,
    y: 22
  },
  {
    x: 14,
    y: 23
  },
  {
    x: 14,
    y: 24
  },
  {
    x: 14,
    y: 25
  },
  {
    x: 14,
    y: 26
  },
  {
    x: 14,
    y: 27
  },
  {
    x: 14,
    y: 28
  },
  {
    x: 14,
    y: 29
  },
  {
    x: 14,
    y: 30
  },
  {
    x: 14,
    y: 31
  },
  {
    x: 15,
    y: 13
  },
  {
    x: 16,
    y: 13
  },
  {
    x: 15,
    y: 14
  },
  {
    x: 15,
    y: 15
  },
  {
    x: 15,
    y: 16
  },
  {
    x: 15,
    y: 17
  },
  {
    x: 15,
    y: 18
  },
  {
    x: 15,
    y: 19
  },
  {
    x: 15,
    y: 20
  },
  {
    x: 15,
    y: 21
  },
  {
    x: 15,
    y: 22
  },
  {
    x: 15,
    y: 23
  },
  {
    x: 15,
    y: 24
  },
  {
    x: 15,
    y: 25
  },
  {
    x: 15,
    y: 26
  },
  {
    x: 15,
    y: 27
  },
  {
    x: 15,
    y: 28
  },
  {
    x: 15,
    y: 29
  },
  {
    x: 15,
    y: 30
  },
  {
    x: 15,
    y: 31
  },
  {
    x: 16,
    y: 14
  },
  {
    x: 16,
    y: 15
  },
  {
    x: 16,
    y: 16
  },
  {
    x: 16,
    y: 17
  },
  {
    x: 16,
    y: 18
  },
  {
    x: 16,
    y: 19
  },
  {
    x: 16,
    y: 20
  },
  {
    x: 16,
    y: 21
  },
  {
    x: 16,
    y: 22
  },
  {
    x: 16,
    y: 23
  },
  {
    x: 16,
    y: 24
  },
  {
    x: 16,
    y: 25
  },
  {
    x: 16,
    y: 26
  },
  {
    x: 16,
    y: 27
  },
  {
    x: 16,
    y: 28
  },
  {
    x: 16,
    y: 29
  },
  {
    x: 16,
    y: 30
  },
  {
    x: 16,
    y: 32
  },
  {
    x: 17,
    y: 13
  },
  {
    x: 17,
    y: 14
  },
  {
    x: 17,
    y: 15
  },
  {
    x: 17,
    y: 16
  },
  {
    x: 17,
    y: 17
  },
  {
    x: 17,
    y: 18
  },
  {
    x: 17,
    y: 19
  },
  {
    x: 17,
    y: 20
  },
  {
    x: 17,
    y: 21
  },
  {
    x: 17,
    y: 22
  },
  {
    x: 17,
    y: 23
  },
  {
    x: 17,
    y: 24
  },
  {
    x: 17,
    y: 25
  },
  {
    x: 17,
    y: 26
  },
  {
    x: 17,
    y: 27
  },
  {
    x: 17,
    y: 28
  },
  {
    x: 17,
    y: 29
  },
  {
    x: 17,
    y: 30
  },
  {
    x: 18,
    y: 13
  },
  {
    x: 18,
    y: 14
  },
  {
    x: 18,
    y: 15
  },
  {
    x: 18,
    y: 16
  },
  {
    x: 18,
    y: 17
  },
  {
    x: 18,
    y: 18
  },
  {
    x: 18,
    y: 19
  },
  {
    x: 18,
    y: 20
  },
  {
    x: 18,
    y: 21
  },
  {
    x: 18,
    y: 22
  },
  {
    x: 18,
    y: 23
  },
  {
    x: 18,
    y: 24
  },
  {
    x: 18,
    y: 25
  },
  {
    x: 18,
    y: 26
  },
  {
    x: 18,
    y: 27
  },
  {
    x: 18,
    y: 28
  },
  {
    x: 18,
    y: 29
  },
  {
    x: 18,
    y: 32
  },
  {
    x: 18,
    y: 33
  },
  {
    x: 19,
    y: 13
  },
  {
    x: 19,
    y: 14
  },
  {
    x: 19,
    y: 15
  },
  {
    x: 19,
    y: 16
  },
  {
    x: 19,
    y: 17
  },
  {
    x: 19,
    y: 18
  },
  {
    x: 19,
    y: 19
  },
  {
    x: 19,
    y: 20
  },
  {
    x: 19,
    y: 21
  },
  {
    x: 19,
    y: 22
  },
  {
    x: 19,
    y: 23
  },
  {
    x: 19,
    y: 24
  },
  {
    x: 19,
    y: 25
  },
  {
    x: 19,
    y: 26
  },
  {
    x: 19,
    y: 27
  },
  {
    x: 19,
    y: 28
  },
  {
    x: 19,
    y: 29
  },
  {
    x: 19,
    y: 30
  },
  {
    x: 19,
    y: 31
  },
  {
    x: 19,
    y: 32
  },
  {
    x: 19,
    y: 33
  },
  {
    x: 19,
    y: 34
  },
  {
    x: 20,
    y: 13
  },
  {
    x: 20,
    y: 14
  },
  {
    x: 20,
    y: 15
  },
  {
    x: 20,
    y: 16
  },
  {
    x: 20,
    y: 17
  },
  {
    x: 20,
    y: 18
  },
  {
    x: 20,
    y: 19
  },
  {
    x: 20,
    y: 20
  },
  {
    x: 20,
    y: 21
  },
  {
    x: 20,
    y: 23
  },
  {
    x: 20,
    y: 24
  },
  {
    x: 20,
    y: 25
  },
  {
    x: 20,
    y: 26
  },
  {
    x: 20,
    y: 27
  },
  {
    x: 20,
    y: 28
  },
  {
    x: 20,
    y: 29
  },
  {
    x: 20,
    y: 30
  },
  {
    x: 20,
    y: 31
  },
  {
    x: 20,
    y: 32
  },
  {
    x: 20,
    y: 33
  },
  {
    x: 20,
    y: 34
  },
  {
    x: 20,
    y: 35
  },
  {
    x: 20,
    y: 36
  },
  {
    x: 21,
    y: 13
  },
  {
    x: 21,
    y: 14
  },
  {
    x: 21,
    y: 15
  },
  {
    x: 21,
    y: 16
  },
  {
    x: 21,
    y: 17
  },
  {
    x: 21,
    y: 18
  },
  {
    x: 21,
    y: 19
  },
  {
    x: 21,
    y: 20
  },
  {
    x: 21,
    y: 21
  },
  {
    x: 21,
    y: 23
  },
  {
    x: 21,
    y: 24
  },
  {
    x: 21,
    y: 25
  },
  {
    x: 21,
    y: 26
  },
  {
    x: 21,
    y: 27
  },
  {
    x: 21,
    y: 28
  },
  {
    x: 21,
    y: 29
  },
  {
    x: 21,
    y: 30
  },
  {
    x: 21,
    y: 31
  },
  {
    x: 21,
    y: 32
  },
  {
    x: 21,
    y: 33
  },
  {
    x: 21,
    y: 34
  },
  {
    x: 21,
    y: 35
  },
  {
    x: 33,
    y: 36
  },
  {
    x: 21,
    y: 37
  },
  {
    x: 33,
    y: 37
  },
  {
    x: 21,
    y: 38
  },
  {
    x: 33,
    y: 38
  },
  {
    x: 34,
    y: 38
  },
  {
    x: 21,
    y: 39
  },
  {
    x: 22,
    y: 13
  },
  {
    x: 22,
    y: 14
  },
  {
    x: 22,
    y: 15
  },
  {
    x: 22,
    y: 16
  },
  {
    x: 22,
    y: 17
  },
  {
    x: 22,
    y: 18
  },
  {
    x: 22,
    y: 21
  },
  {
    x: 22,
    y: 23
  },
  {
    x: 22,
    y: 24
  },
  {
    x: 22,
    y: 25
  },
  {
    x: 22,
    y: 26
  },
  {
    x: 22,
    y: 27
  },
  {
    x: 22,
    y: 28
  },
  {
    x: 22,
    y: 29
  },
  {
    x: 22,
    y: 30
  },
  {
    x: 22,
    y: 31
  },
  {
    x: 22,
    y: 32
  },
  {
    x: 22,
    y: 33
  },
  {
    x: 22,
    y: 34
  },
  {
    x: 22,
    y: 35
  },
  {
    x: 22,
    y: 36
  },
  {
    x: 22,
    y: 37
  },
  {
    x: 22,
    y: 38
  },
  {
    x: 22,
    y: 39
  },
  {
    x: 33,
    y: 39
  },
  {
    x: 34,
    y: 39
  },
  {
    x: 22,
    y: 40
  },
  {
    x: 23,
    y: 13
  },
  {
    x: 23,
    y: 14
  },
  {
    x: 23,
    y: 15
  },
  {
    x: 23,
    y: 16
  },
  {
    x: 23,
    y: 17
  },
  {
    x: 23,
    y: 23
  },
  {
    x: 23,
    y: 24
  },
  {
    x: 23,
    y: 25
  },
  {
    x: 23,
    y: 26
  },
  {
    x: 23,
    y: 27
  },
  {
    x: 23,
    y: 28
  },
  {
    x: 23,
    y: 29
  },
  {
    x: 23,
    y: 30
  },
  {
    x: 23,
    y: 31
  },
  {
    x: 23,
    y: 32
  },
  {
    x: 23,
    y: 33
  },
  {
    x: 23,
    y: 34
  },
  {
    x: 23,
    y: 35
  },
  {
    x: 23,
    y: 36
  },
  {
    x: 23,
    y: 37
  },
  {
    x: 23,
    y: 38
  },
  {
    x: 23,
    y: 39
  },
  {
    x: 23,
    y: 40
  },
  {
    x: 24,
    y: 13
  },
  {
    x: 24,
    y: 14
  },
  {
    x: 24,
    y: 15
  },
  {
    x: 24,
    y: 24
  },
  {
    x: 24,
    y: 25
  },
  {
    x: 24,
    y: 26
  },
  {
    x: 24,
    y: 27
  },
  {
    x: 24,
    y: 28
  },
  {
    x: 24,
    y: 29
  },
  {
    x: 24,
    y: 30
  },
  {
    x: 24,
    y: 31
  },
  {
    x: 24,
    y: 32
  },
  {
    x: 24,
    y: 33
  },
  {
    x: 24,
    y: 34
  },
  {
    x: 24,
    y: 35
  },
  {
    x: 24,
    y: 36
  },
  {
    x: 25,
    y: 36
  },
  {
    x: 27,
    y: 36
  },
  {
    x: 24,
    y: 37
  },
  {
    x: 24,
    y: 38
  },
  {
    x: 24,
    y: 39
  },
  {
    x: 24,
    y: 40
  },
  {
    x: 25,
    y: 25
  },
  {
    x: 25,
    y: 26
  },
  {
    x: 25,
    y: 27
  },
  {
    x: 25,
    y: 28
  },
  {
    x: 25,
    y: 29
  },
  {
    x: 25,
    y: 30
  },
  {
    x: 25,
    y: 32
  },
  {
    x: 25,
    y: 33
  },
  {
    x: 25,
    y: 34
  },
  {
    x: 25,
    y: 35
  },
  {
    x: 25,
    y: 37
  },
  {
    x: 25,
    y: 38
  },
  {
    x: 25,
    y: 39
  },
  {
    x: 25,
    y: 40
  },
  {
    x: 26,
    y: 26
  },
  {
    x: 26,
    y: 27
  },
  {
    x: 26,
    y: 28
  },
  {
    x: 26,
    y: 29
  },
  {
    x: 26,
    y: 30
  },
  {
    x: 26,
    y: 31
  },
  {
    x: 26,
    y: 32
  },
  {
    x: 26,
    y: 33
  },
  {
    x: 26,
    y: 34
  },
  {
    x: 26,
    y: 35
  },
  {
    x: 26,
    y: 36
  },
  {
    x: 26,
    y: 37
  },
  {
    x: 26,
    y: 38
  },
  {
    x: 26,
    y: 39
  },
  {
    x: 26,
    y: 40
  },
  {
    x: 27,
    y: 28
  },
  {
    x: 27,
    y: 29
  },
  {
    x: 27,
    y: 30
  },
  {
    x: 27,
    y: 32
  },
  {
    x: 27,
    y: 33
  },
  {
    x: 27,
    y: 34
  },
  {
    x: 27,
    y: 35
  },
  {
    x: 27,
    y: 37
  },
  {
    x: 27,
    y: 38
  },
  {
    x: 27,
    y: 39
  },
  {
    x: 27,
    y: 40
  },
  {
    x: 28,
    y: 32
  },
  {
    x: 28,
    y: 36
  },
  {
    x: 28,
    y: 33
  },
  {
    x: 28,
    y: 34
  },
  {
    x: 28,
    y: 35
  },
  {
    x: 28,
    y: 37
  },
  {
    x: 28,
    y: 38
  },
  {
    x: 28,
    y: 39
  },
  {
    x: 28,
    y: 40
  },
  {
    x: 29,
    y: 32
  },
  {
    x: 29,
    y: 33
  },
  {
    x: 29,
    y: 34
  },
  {
    x: 29,
    y: 35
  },
  {
    x: 29,
    y: 36
  },
  {
    x: 29,
    y: 37
  },
  {
    x: 29,
    y: 38
  },
  {
    x: 29,
    y: 39
  },
  {
    x: 29,
    y: 40
  },
  {
    x: 30,
    y: 32
  },
  {
    x: 30,
    y: 33
  },
  {
    x: 30,
    y: 34
  },
  {
    x: 30,
    y: 35
  },
  {
    x: 30,
    y: 36
  },
  {
    x: 30,
    y: 37
  },
  {
    x: 30,
    y: 38
  },
  {
    x: 30,
    y: 39
  },
  {
    x: 30,
    y: 40
  },
  {
    x: 31,
    y: 33
  },
  {
    x: 31,
    y: 34
  },
  {
    x: 31,
    y: 35
  },
  {
    x: 31,
    y: 36
  },
  {
    x: 31,
    y: 37
  },
  {
    x: 31,
    y: 38
  },
  {
    x: 31,
    y: 39
  },
  {
    x: 31,
    y: 40
  },
  {
    x: 32,
    y: 34
  },
  {
    x: 32,
    y: 35
  },
  {
    x: 32,
    y: 36
  },
  {
    x: 32,
    y: 37
  },
  {
    x: 32,
    y: 38
  },
  {
    x: 32,
    y: 39
  },
  {
    x: 32,
    y: 40
  },
  {
    x: 33,
    y: 40
  },
  {
    x: 34,
    y: 40
  },
  {
    x: 35,
    y: 40
  },
  {
    x: 33,
    y: 41
  },
  {
    x: 34,
    y: 41
  },
  {
    x: 35,
    y: 41
  },
  {
    x: 33,
    y: 42
  },
  {
    x: 34,
    y: 42
  },
  {
    x: 35,
    y: 42
  },
  {
    x: 33,
    y: 43
  },
  {
    x: 34,
    y: 43
  },
  {
    x: 35,
    y: 43
  },
  {
    x: 37,
    y: 43
  },
  {
    x: 38,
    y: 43
  },
  {
    x: 39,
    y: 43
  },
  {
    x: 40,
    y: 43
  },
  {
    x: 33,
    y: 44
  },
  {
    x: 34,
    y: 44
  },
  {
    x: 37,
    y: 44
  },
  {
    x: 38,
    y: 44
  },
  {
    x: 39,
    y: 44
  },
  {
    x: 40,
    y: 44
  },
  {
    x: 33,
    y: 45
  },
  {
    x: 34,
    y: 45
  },
  {
    x: 35,
    y: 45
  },
  {
    x: 36,
    y: 45
  },
  {
    x: 37,
    y: 45
  },
  {
    x: 38,
    y: 45
  },
  {
    x: 39,
    y: 45
  },
  {
    x: 40,
    y: 45
  },
  {
    x: 33,
    y: 46
  },
  {
    x: 34,
    y: 46
  },
  {
    x: 35,
    y: 46
  },
  {
    x: 36,
    y: 46
  },
  {
    x: 37,
    y: 46
  },
  {
    x: 38,
    y: 46
  },
  {
    x: 39,
    y: 46
  },
  {
    x: 40,
    y: 46
  },
  {
    x: 41,
    y: 46
  },
  {
    x: 33,
    y: 47
  },
  {
    x: 34,
    y: 47
  },
  {
    x: 35,
    y: 47
  },
  {
    x: 36,
    y: 47
  },
  {
    x: 37,
    y: 47
  },
  {
    x: 38,
    y: 47
  },
  {
    x: 39,
    y: 47
  },
  {
    x: 40,
    y: 47
  },
  {
    x: 41,
    y: 47
  },
  {
    x: 33,
    y: 48
  },
  {
    x: 34,
    y: 48
  },
  {
    x: 35,
    y: 48
  },
  {
    x: 36,
    y: 48
  },
  {
    x: 37,
    y: 48
  },
  {
    x: 38,
    y: 48
  },
  {
    x: 39,
    y: 48
  },
  {
    x: 40,
    y: 48
  },
  {
    x: 41,
    y: 48
  },
  {
    x: 33,
    y: 49
  },
  {
    x: 34,
    y: 49
  },
  {
    x: 35,
    y: 49
  },
  {
    x: 36,
    y: 49
  },
  {
    x: 37,
    y: 49
  },
  {
    x: 38,
    y: 49
  },
  {
    x: 39,
    y: 49
  },
  {
    x: 40,
    y: 49
  },
  {
    x: 33,
    y: 50
  },
  {
    x: 34,
    y: 50
  },
  {
    x: 35,
    y: 50
  },
  {
    x: 36,
    y: 50
  },
  {
    x: 37,
    y: 50
  },
  {
    x: 38,
    y: 50
  },
  {
    x: 39,
    y: 50
  },
  {
    x: 40,
    y: 50
  },
  {
    x: 33,
    y: 51
  },
  {
    x: 34,
    y: 51
  },
  {
    x: 35,
    y: 51
  },
  {
    x: 36,
    y: 51
  },
  {
    x: 37,
    y: 51
  },
  {
    x: 38,
    y: 51
  },
  {
    x: 39,
    y: 51
  },
  {
    x: 40,
    y: 51
  },
  {
    x: 11,
    y: 52
  },
  {
    x: 33,
    y: 52
  },
  {
    x: 34,
    y: 52
  },
  {
    x: 35,
    y: 52
  },
  {
    x: 36,
    y: 52
  },
  {
    x: 37,
    y: 52
  },
  {
    x: 38,
    y: 52
  },
  {
    x: 39,
    y: 52
  },
  {
    x: 11,
    y: 53
  },
  {
    x: 12,
    y: 51
  },
  {
    x: 12,
    y: 52
  },
  {
    x: 12,
    y: 53
  },
  {
    x: 13,
    y: 45
  },
  {
    x: 13,
    y: 51
  },
  {
    x: 13,
    y: 52
  },
  {
    x: 13,
    y: 53
  },
  {
    x: 14,
    y: 41
  },
  {
    x: 14,
    y: 42
  },
  {
    x: 14,
    y: 44
  },
  {
    x: 14,
    y: 45
  },
  {
    x: 14,
    y: 50
  },
  {
    x: 14,
    y: 51
  },
  {
    x: 14,
    y: 52
  },
  {
    x: 14,
    y: 53
  },
  {
    x: 15,
    y: 41
  },
  {
    x: 15,
    y: 42
  },
  {
    x: 15,
    y: 43
  },
  {
    x: 15,
    y: 44
  },
  {
    x: 15,
    y: 50
  },
  {
    x: 15,
    y: 51
  },
  {
    x: 15,
    y: 52
  },
  {
    x: 15,
    y: 53
  },
  {
    x: 16,
    y: 42
  },
  {
    x: 16,
    y: 44
  },
  {
    x: 16,
    y: 46
  },
  {
    x: 16,
    y: 47
  },
  {
    x: 16,
    y: 48
  },
  {
    x: 16,
    y: 49
  },
  {
    x: 16,
    y: 50
  },
  {
    x: 16,
    y: 51
  },
  {
    x: 16,
    y: 52
  },
  {
    x: 16,
    y: 53
  },
  {
    x: 17,
    y: 42
  },
  {
    x: 17,
    y: 43
  },
  {
    x: 17,
    y: 44
  },
  {
    x: 17,
    y: 45
  },
  {
    x: 17,
    y: 46
  },
  {
    x: 17,
    y: 47
  },
  {
    x: 17,
    y: 48
  },
  {
    x: 17,
    y: 49
  },
  {
    x: 17,
    y: 50
  },
  {
    x: 17,
    y: 51
  },
  {
    x: 17,
    y: 52
  },
  {
    x: 17,
    y: 53
  },
  {
    x: 18,
    y: 42
  },
  {
    x: 18,
    y: 43
  },
  {
    x: 18,
    y: 44
  },
  {
    x: 18,
    y: 45
  },
  {
    x: 18,
    y: 46
  },
  {
    x: 18,
    y: 47
  },
  {
    x: 18,
    y: 48
  },
  {
    x: 18,
    y: 49
  },
  {
    x: 18,
    y: 50
  },
  {
    x: 18,
    y: 51
  },
  {
    x: 18,
    y: 52
  },
  {
    x: 18,
    y: 53
  },
  {
    x: 19,
    y: 41
  },
  {
    x: 19,
    y: 42
  },
  {
    x: 19,
    y: 43
  },
  {
    x: 19,
    y: 44
  },
  {
    x: 19,
    y: 45
  },
  {
    x: 19,
    y: 46
  },
  {
    x: 19,
    y: 47
  },
  {
    x: 19,
    y: 48
  },
  {
    x: 19,
    y: 49
  },
  {
    x: 19,
    y: 50
  },
  {
    x: 19,
    y: 51
  },
  {
    x: 19,
    y: 52
  },
  {
    x: 19,
    y: 53
  },
  {
    x: 20,
    y: 41
  },
  {
    x: 20,
    y: 42
  },
  {
    x: 20,
    y: 43
  },
  {
    x: 20,
    y: 44
  },
  {
    x: 20,
    y: 45
  },
  {
    x: 20,
    y: 46
  },
  {
    x: 20,
    y: 47
  },
  {
    x: 20,
    y: 48
  },
  {
    x: 20,
    y: 49
  },
  {
    x: 20,
    y: 50
  },
  {
    x: 20,
    y: 51
  },
  {
    x: 20,
    y: 52
  },
  {
    x: 20,
    y: 53
  },
  {
    x: 21,
    y: 40
  },
  {
    x: 21,
    y: 41
  },
  {
    x: 21,
    y: 42
  },
  {
    x: 21,
    y: 43
  },
  {
    x: 21,
    y: 44
  },
  {
    x: 21,
    y: 45
  },
  {
    x: 21,
    y: 46
  },
  {
    x: 21,
    y: 47
  },
  {
    x: 21,
    y: 48
  },
  {
    x: 21,
    y: 49
  },
  {
    x: 21,
    y: 50
  },
  {
    x: 21,
    y: 51
  },
  {
    x: 21,
    y: 52
  },
  {
    x: 21,
    y: 53
  },
  {
    x: 33,
    y: 53
  },
  {
    x: 34,
    y: 53
  },
  {
    x: 35,
    y: 53
  },
  {
    x: 36,
    y: 53
  },
  {
    x: 37,
    y: 53
  },
  {
    x: 38,
    y: 53
  },
  {
    x: 39,
    y: 53
  },
  {
    x: 12,
    y: 54
  },
  {
    x: 21,
    y: 54
  },
  {
    x: 22,
    y: 41
  },
  {
    x: 22,
    y: 42
  },
  {
    x: 22,
    y: 43
  },
  {
    x: 22,
    y: 44
  },
  {
    x: 22,
    y: 45
  },
  {
    x: 22,
    y: 46
  },
  {
    x: 22,
    y: 47
  },
  {
    x: 22,
    y: 48
  },
  {
    x: 22,
    y: 49
  },
  {
    x: 22,
    y: 50
  },
  {
    x: 22,
    y: 51
  },
  {
    x: 22,
    y: 52
  },
  {
    x: 22,
    y: 53
  },
  {
    x: 22,
    y: 54
  },
  {
    x: 23,
    y: 41
  },
  {
    x: 23,
    y: 42
  },
  {
    x: 23,
    y: 43
  },
  {
    x: 23,
    y: 44
  },
  {
    x: 23,
    y: 45
  },
  {
    x: 23,
    y: 46
  },
  {
    x: 23,
    y: 47
  },
  {
    x: 23,
    y: 48
  },
  {
    x: 23,
    y: 49
  },
  {
    x: 23,
    y: 50
  },
  {
    x: 23,
    y: 51
  },
  {
    x: 23,
    y: 52
  },
  {
    x: 23,
    y: 53
  },
  {
    x: 23,
    y: 54
  },
  {
    x: 24,
    y: 41
  },
  {
    x: 24,
    y: 42
  },
  {
    x: 24,
    y: 43
  },
  {
    x: 25,
    y: 43
  },
  {
    x: 27,
    y: 43
  },
  {
    x: 28,
    y: 43
  },
  {
    x: 24,
    y: 44
  },
  {
    x: 24,
    y: 45
  },
  {
    x: 24,
    y: 46
  },
  {
    x: 24,
    y: 47
  },
  {
    x: 24,
    y: 48
  },
  {
    x: 24,
    y: 49
  },
  {
    x: 24,
    y: 50
  },
  {
    x: 24,
    y: 51
  },
  {
    x: 24,
    y: 52
  },
  {
    x: 24,
    y: 53
  },
  {
    x: 24,
    y: 54
  },
  {
    x: 25,
    y: 41
  },
  {
    x: 25,
    y: 42
  },
  {
    x: 25,
    y: 44
  },
  {
    x: 25,
    y: 46
  },
  {
    x: 25,
    y: 47
  },
  {
    x: 25,
    y: 48
  },
  {
    x: 25,
    y: 49
  },
  {
    x: 25,
    y: 50
  },
  {
    x: 25,
    y: 51
  },
  {
    x: 25,
    y: 52
  },
  {
    x: 25,
    y: 53
  },
  {
    x: 25,
    y: 54
  },
  {
    x: 26,
    y: 41
  },
  {
    x: 26,
    y: 42
  },
  {
    x: 26,
    y: 43
  },
  {
    x: 26,
    y: 44
  },
  {
    x: 26,
    y: 45
  },
  {
    x: 25,
    y: 45
  },
  {
    x: 27,
    y: 45
  },
  {
    x: 28,
    y: 45
  },
  {
    x: 26,
    y: 46
  },
  {
    x: 26,
    y: 47
  },
  {
    x: 26,
    y: 48
  },
  {
    x: 26,
    y: 49
  },
  {
    x: 26,
    y: 50
  },
  {
    x: 26,
    y: 51
  },
  {
    x: 26,
    y: 52
  },
  {
    x: 26,
    y: 53
  },
  {
    x: 26,
    y: 54
  },
  {
    x: 27,
    y: 41
  },
  {
    x: 27,
    y: 42
  },
  {
    x: 27,
    y: 44
  },
  {
    x: 27,
    y: 46
  },
  {
    x: 27,
    y: 47
  },
  {
    x: 27,
    y: 48
  },
  {
    x: 27,
    y: 49
  },
  {
    x: 27,
    y: 50
  },
  {
    x: 27,
    y: 51
  },
  {
    x: 27,
    y: 52
  },
  {
    x: 27,
    y: 53
  },
  {
    x: 27,
    y: 54
  },
  {
    x: 28,
    y: 41
  },
  {
    x: 28,
    y: 42
  },
  {
    x: 28,
    y: 44
  },
  {
    x: 28,
    y: 46
  },
  {
    x: 28,
    y: 47
  },
  {
    x: 28,
    y: 48
  },
  {
    x: 28,
    y: 49
  },
  {
    x: 28,
    y: 50
  },
  {
    x: 28,
    y: 51
  },
  {
    x: 28,
    y: 52
  },
  {
    x: 28,
    y: 53
  },
  {
    x: 28,
    y: 54
  },
  {
    x: 29,
    y: 41
  },
  {
    x: 29,
    y: 42
  },
  {
    x: 29,
    y: 43
  },
  {
    x: 29,
    y: 44
  },
  {
    x: 29,
    y: 45
  },
  {
    x: 29,
    y: 46
  },
  {
    x: 29,
    y: 47
  },
  {
    x: 29,
    y: 48
  },
  {
    x: 29,
    y: 49
  },
  {
    x: 29,
    y: 50
  },
  {
    x: 29,
    y: 51
  },
  {
    x: 29,
    y: 52
  },
  {
    x: 29,
    y: 53
  },
  {
    x: 29,
    y: 54
  },
  {
    x: 30,
    y: 41
  },
  {
    x: 30,
    y: 42
  },
  {
    x: 30,
    y: 43
  },
  {
    x: 30,
    y: 44
  },
  {
    x: 30,
    y: 45
  },
  {
    x: 30,
    y: 46
  },
  {
    x: 30,
    y: 47
  },
  {
    x: 30,
    y: 48
  },
  {
    x: 30,
    y: 49
  },
  {
    x: 30,
    y: 50
  },
  {
    x: 30,
    y: 51
  },
  {
    x: 30,
    y: 52
  },
  {
    x: 30,
    y: 53
  },
  {
    x: 30,
    y: 54
  },
  {
    x: 31,
    y: 41
  },
  {
    x: 31,
    y: 42
  },
  {
    x: 31,
    y: 43
  },
  {
    x: 31,
    y: 44
  },
  {
    x: 31,
    y: 45
  },
  {
    x: 31,
    y: 46
  },
  {
    x: 31,
    y: 47
  },
  {
    x: 31,
    y: 48
  },
  {
    x: 31,
    y: 49
  },
  {
    x: 31,
    y: 50
  },
  {
    x: 31,
    y: 51
  },
  {
    x: 31,
    y: 52
  },
  {
    x: 31,
    y: 53
  },
  {
    x: 31,
    y: 54
  },
  {
    x: 32,
    y: 41
  },
  {
    x: 32,
    y: 42
  },
  {
    x: 32,
    y: 43
  },
  {
    x: 32,
    y: 44
  },
  {
    x: 32,
    y: 45
  },
  {
    x: 32,
    y: 46
  },
  {
    x: 32,
    y: 47
  },
  {
    x: 32,
    y: 48
  },
  {
    x: 32,
    y: 49
  },
  {
    x: 32,
    y: 50
  },
  {
    x: 32,
    y: 51
  },
  {
    x: 32,
    y: 52
  },
  {
    x: 32,
    y: 53
  },
  {
    x: 32,
    y: 54
  },
  {
    x: 33,
    y: 54
  },
  {
    x: 34,
    y: 54
  },
  {
    x: 35,
    y: 54
  },
  {
    x: 36,
    y: 54
  },
  {
    x: 37,
    y: 54
  },
  {
    x: 38,
    y: 54
  },
  {
    x: 33,
    y: 55
  },
  {
    x: 34,
    y: 55
  },
  {
    x: 35,
    y: 55
  },
  {
    x: 36,
    y: 55
  },
  {
    x: 37,
    y: 55
  },
  {
    x: 38,
    y: 55
  },
  {
    x: 39,
    y: 55
  },
  {
    x: 40,
    y: 55
  },
  {
    x: 33,
    y: 56
  },
  {
    x: 34,
    y: 56
  },
  {
    x: 35,
    y: 56
  },
  {
    x: 36,
    y: 56
  },
  {
    x: 37,
    y: 56
  },
  {
    x: 38,
    y: 56
  },
  {
    x: 39,
    y: 56
  },
  {
    x: 40,
    y: 56
  },
  {
    x: 33,
    y: 57
  },
  {
    x: 34,
    y: 57
  },
  {
    x: 35,
    y: 57
  },
  {
    x: 36,
    y: 57
  },
  {
    x: 37,
    y: 57
  },
  {
    x: 38,
    y: 57
  },
  {
    x: 39,
    y: 57
  },
  {
    x: 40,
    y: 57
  },
  {
    x: 33,
    y: 58
  },
  {
    x: 34,
    y: 58
  },
  {
    x: 35,
    y: 58
  },
  {
    x: 36,
    y: 58
  },
  {
    x: 37,
    y: 58
  },
  {
    x: 10,
    y: 64
  },
  {
    x: 11,
    y: 62
  },
  {
    x: 11,
    y: 63
  },
  {
    x: 11,
    y: 64
  },
  {
    x: 11,
    y: 65
  },
  {
    x: 12,
    y: 61
  },
  {
    x: 12,
    y: 62
  },
  {
    x: 12,
    y: 63
  },
  {
    x: 12,
    y: 64
  },
  {
    x: 13,
    y: 54
  },
  {
    x: 13,
    y: 60
  },
  {
    x: 13,
    y: 61
  },
  {
    x: 13,
    y: 62
  },
  {
    x: 13,
    y: 63
  },
  {
    x: 14,
    y: 57
  },
  {
    x: 14,
    y: 58
  },
  {
    x: 14,
    y: 59
  },
  {
    x: 14,
    y: 60
  },
  {
    x: 14,
    y: 61
  },
  {
    x: 14,
    y: 62
  },
  {
    x: 15,
    y: 58
  },
  {
    x: 15,
    y: 59
  },
  {
    x: 15,
    y: 60
  },
  {
    x: 15,
    y: 61
  },
  {
    x: 15,
    y: 62
  },
  {
    x: 16,
    y: 54
  },
  {
    x: 16,
    y: 57
  },
  {
    x: 16,
    y: 59
  },
  {
    x: 16,
    y: 61
  },
  {
    x: 16,
    y: 62
  },
  {
    x: 17,
    y: 54
  },
  {
    x: 17,
    y: 57
  },
  {
    x: 17,
    y: 58
  },
  {
    x: 17,
    y: 59
  },
  {
    x: 17,
    y: 60
  },
  {
    x: 17,
    y: 61
  },
  {
    x: 17,
    y: 62
  },
  {
    x: 17,
    y: 63
  },
  {
    x: 18,
    y: 54
  },
  {
    x: 18,
    y: 55
  },
  {
    x: 18,
    y: 57
  },
  {
    x: 18,
    y: 58
  },
  {
    x: 18,
    y: 59
  },
  {
    x: 18,
    y: 60
  },
  {
    x: 18,
    y: 61
  },
  {
    x: 18,
    y: 62
  },
  {
    x: 18,
    y: 63
  },
  {
    x: 19,
    y: 54
  },
  {
    x: 19,
    y: 55
  },
  {
    x: 19,
    y: 57
  },
  {
    x: 19,
    y: 58
  },
  {
    x: 19,
    y: 59
  },
  {
    x: 19,
    y: 60
  },
  {
    x: 19,
    y: 61
  },
  {
    x: 19,
    y: 62
  },
  {
    x: 20,
    y: 54
  },
  {
    x: 20,
    y: 55
  },
  {
    x: 20,
    y: 57
  },
  {
    x: 20,
    y: 58
  },
  {
    x: 20,
    y: 59
  },
  {
    x: 20,
    y: 60
  },
  {
    x: 21,
    y: 56
  },
  {
    x: 21,
    y: 57
  },
  {
    x: 21,
    y: 58
  },
  {
    x: 21,
    y: 59
  },
  {
    x: 22,
    y: 55
  },
  {
    x: 22,
    y: 56
  },
  {
    x: 22,
    y: 57
  },
  {
    x: 22,
    y: 58
  },
  {
    x: 22,
    y: 59
  },
  {
    x: 23,
    y: 55
  },
  {
    x: 23,
    y: 56
  },
  {
    x: 23,
    y: 57
  },
  {
    x: 23,
    y: 58
  },
  {
    x: 23,
    y: 59
  },
  {
    x: 24,
    y: 55
  },
  {
    x: 24,
    y: 56
  },
  {
    x: 24,
    y: 57
  },
  {
    x: 24,
    y: 58
  },
  {
    x: 24,
    y: 59
  },
  {
    x: 24,
    y: 60
  },
  {
    x: 25,
    y: 55
  },
  {
    x: 25,
    y: 56
  },
  {
    x: 25,
    y: 57
  },
  {
    x: 25,
    y: 59
  },
  {
    x: 25,
    y: 61
  },
  {
    x: 26,
    y: 55
  },
  {
    x: 26,
    y: 56
  },
  {
    x: 26,
    y: 57
  },
  {
    x: 26,
    y: 58
  },
  {
    x: 26,
    y: 59
  },
  {
    x: 26,
    y: 60
  },
  {
    x: 27,
    y: 55
  },
  {
    x: 27,
    y: 56
  },
  {
    x: 27,
    y: 57
  },
  {
    x: 27,
    y: 59
  },
  {
    x: 28,
    y: 55
  },
  {
    x: 28,
    y: 56
  },
  {
    x: 28,
    y: 57
  },
  {
    x: 28,
    y: 59
  },
  {
    x: 29,
    y: 55
  },
  {
    x: 29,
    y: 56
  },
  {
    x: 29,
    y: 57
  },
  {
    x: 29,
    y: 58
  },
  {
    x: 29,
    y: 59
  },
  {
    x: 29,
    y: 60
  },
  {
    x: 30,
    y: 55
  },
  {
    x: 30,
    y: 56
  },
  {
    x: 30,
    y: 57
  },
  {
    x: 30,
    y: 58
  },
  {
    x: 30,
    y: 60
  },
  {
    x: 31,
    y: 55
  },
  {
    x: 31,
    y: 56
  },
  {
    x: 31,
    y: 57
  },
  {
    x: 31,
    y: 58
  },
  {
    x: 31,
    y: 59
  },
  {
    x: 32,
    y: 55
  },
  {
    x: 32,
    y: 56
  },
  {
    x: 32,
    y: 57
  },
  {
    x: 32,
    y: 58
  }
]
