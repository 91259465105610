import React from 'react'
import { x } from '@xstyled/styled-components'
import { Chessboard, getConfig } from '@pool/components/chessboard/Chessboard'

const cards_config = [
  {
    cell_coordinates: [
      { x: 1, y: 0 },
      { x: 3, y: 0 },
      { x: 0, y: 1 },
      { x: 2, y: 1 },
      { x: 4, y: 1 },
      { x: 1, y: 2 },
      { x: 3, y: 2 }
    ],
    title: 'Networks',
    value: '70+',
    subtitle:
      'Supporting all leading Blockchain networks, LinkPool can scale without limitation while providing high-throughput, resilience and unmatched performance.'
  },
  {
    cell_coordinates: [
      { x: 2, y: 0 },
      { x: 4, y: 0 },
      { x: 1, y: 1 },
      { x: 3, y: 1 },
      { x: 0, y: 2 },
      { x: 2, y: 2 }
    ],
    title: 'Requests',
    value: '30bn+',
    subtitle:
      'With tens of billions requests served monthly, LinkPool has proven capability that is able to meet exceeding demand within the Web3 industry.'
  },
  {
    cell_coordinates: [
      { x: 0, y: 0 },
      { x: 2, y: 0 },
      { x: 4, y: 0 },
      { x: 1, y: 1 },
      { x: 3, y: 1 },
      { x: 0, y: 2 },
      { x: 2, y: 2 },
      { x: 4, y: 2 }
    ],
    title: 'Connected Data Centers',
    value: '2',
    subtitle:
      'With industry-leading infrastructure powered by two Tier 4 data centers, we provide unmatched edge-network connectivity with the latest high-end hardware.'
  }
]

const Card = ({ config }) => {
  const icon_config = getConfig({
    cell_height: 17,
    cell_width: 8,
    cell_amount_x: 5,
    cell_amount_y: 3,
    axis_x: false,
    axis_y: false,
    cell_color: '#73C4A8'
  })

  return (
    <x.article bg="blackGreen" p="40px" borderRadius="5px">
      <x.div
        mb="70px"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <x.p fontSize="70px" fontWeight="600" color="lime" lineHeight="100%">
          {config.value}
        </x.p>
        <Chessboard
          config={icon_config}
          coordinates={config.cell_coordinates}
        />
      </x.div>
      <x.div>
        <x.h2 fontSize="20px" fontWeight="500" color="lightGreen" mb="20px">
          {config.title}
        </x.h2>
        <x.p fontSize="14px" fontWeight="500" color="lime" lineHeight="200%">
          {config.subtitle}
        </x.p>
      </x.div>
    </x.article>
  )
}

export function Solutions() {
  return (
    <x.section
      w={{ _: '100%', maxWidth: 'maxWidth' }}
      id="solutions"
      display="flex"
      justifyContent="end"
      mt={{ _: '25px', xl: '75px' }}
      pt={{ _: '25px', xl: '75px' }}
      mx="auto"
      px={{ _: '30px', md: '60px' }}>
      <x.div w={{ _: '100%', lg: '1290px' }}>
        <x.div mb="70px">
          <x.h3
            // fontSize={{ _: '16px', xl: '20px' }}
            fontSize="20px"
            fontWeight="600"
            color="lightGreen"
            mb="15px"
            lineHeight="100%">
            Our Metrics
          </x.h3>
          <x.h2
            // fontSize={{ _: '18px', xl: '30px' }}
            fontSize="30px"
            fontWeight="600"
            color="lime"
            lineHeight="130%">
            The leading provider of Web3 infrastructure.
          </x.h2>
        </x.div>
        <x.div display="grid" gridTemplateColumns={{ _: 1, lg: 3 }} gap="34px">
          <Card config={cards_config[0]} />
          <Card config={cards_config[1]} />
          <Card config={cards_config[2]} />
        </x.div>
      </x.div>
    </x.section>
  )
}
