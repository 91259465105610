import React from 'react'
import styled, { x, useUp } from '@xstyled/styled-components'
import chainlink from '@pool/assets/logos/chainlink.svg'
import google from '@pool/assets/logos/google.svg'
import ibm from '@pool/assets/logos/ibm.svg'

import xbto from '@pool/assets/logos/xbto.svg'
import tiingo from '@pool/assets/logos/tiingo.svg'
import framework from '@pool/assets/logos/framework.svg'
import dxfeed from '@pool/assets/logos/dxfeed.svg'

import lexisnexis from '@pool/assets/logos/lexisnexis.svg'
import ap from '@pool/assets/logos/ap.svg'
import finage from '@pool/assets/logos/finage.svg'
import accuweather from '@pool/assets/logos/accuweather.svg'
import mastercard from '@pool/assets/logos/mastercard.svg'
import tradermade from '@pool/assets/logos/trademade.svg'
import twelvedata from '@pool/assets/logos/twelvedata.svg'

import dune from '@pool/assets/logos/dune.svg'
import rarity from '@pool/assets/logos/rarity.svg'
import opensea from '@pool/assets/logos/opensea.svg'

const CarouselStyles = styled.divBox`
  --item-gap: 10px;
  --item-width: calc((100vw / var(--visible-items)) - var(--item-gap));
  overflow: hidden;

  .carousel-container {
    display: flex;
    overflow: hidden;
    width: 100vw;
    height: 200px;
    position: relative;
  }

  .carousel-track {
    display: flex;
    gap: var(--item-gap);
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
  }

  .carousel-item {
    flex: 0 0 auto;
    width: var(--item-width);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

function Logo({ src, title, ...props }) {
  return (
    <x.div
      display="flex"
      flexDirection="column"
      gap="3"
      justifyContent="center"
      alignItems="center">
      <x.img src={src} maxHeight="60px" {...props} />
      <x.p text="sm" color="lime" opacity="0.4">
        {title}
      </x.p>
    </x.div>
  )
}

const Carousel = ({ items, visibleItems }) => {
  const trackRef = React.useRef(null)

  React.useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--visible-items', visibleItems)
    const track = trackRef.current
    let start = 0
    const totalWidth = track.scrollWidth / 2

    const animate = () => {
      start -= 1
      if (Math.abs(start) >= totalWidth) {
        start = 0
      }
      track.style.transform = `translateX(${start}px)`
      requestAnimationFrame(animate)
    }

    requestAnimationFrame(animate)
  }, [visibleItems])

  const clonedItems = [...items, ...items]

  return (
    <CarouselStyles>
      <div className="carousel-container">
        <div className="carousel-track" ref={trackRef}>
          {clonedItems.map((item, index) => (
            <div className="carousel-item" key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </CarouselStyles>
  )
}

export function Logos() {
  const isUp = useUp('xl')

  const items = [
    <Logo src={google} key="Google" title="Google" />,
    <Logo src={ibm} key="IBM" title="IBM" />,
    <Logo src={ap} key="Associated Press" title="Associated Press" />,
    <Logo src={accuweather} key="Accuweather" title="Accuweather" />,
    <Logo src={xbto} key="XBTO" title="XBTO" />,
    <Logo src={tiingo} key="Tiingo" title="Tiingo" />,
    <Logo src={framework} key="Framework" title="Framework" />,
    <Logo src={dxfeed} key="dxFeed" title="dxFeed" />,
    <Logo src={chainlink} key="Chainlink" title="Chainlink" />,
    <Logo src={lexisnexis} key="LexisNexis" title="LexisNexis" />,
    <Logo src={finage} key="Finage" title="Finage" />,
    <Logo src={mastercard} key="Mastercard" title="Mastercard" />,
    <Logo src={tradermade} key="TraderMade" title="TraderMade" />,
    <Logo src={twelvedata} key="TwelveData" title="TwelveData" />,
    <Logo src={lexisnexis} key="LexisNexis" title="LexisNexis" />,
    <Logo src={finage} key="Finage" title="Finage" />,
    <Logo src={mastercard} key="Mastercard" title="Mastercard" />,
    <Logo src={tradermade} key="TraderMade" title="TraderMade" />,
    <Logo src={twelvedata} key="TwelveData" title="TwelveData" />,
    <Logo src={dune} key="Dune" title="Dune" h="80px" />,
    <Logo src={rarity} key="Rarify" title="Rarify" h="70px" />,
    <Logo src={opensea} key="OpenSea" title="OpenSea" h="80px" />
  ]
  return (
    <x.section
      id="logos"
      mt="150px"
      mb="150px"
      w={{ _: '100%', maxWidth: 'midWidth' }}
      mx="auto"
      px={{ _: '30px', md: '60px' }}>
      <x.h1 fontSize="20px" fontWeight="600" color="lime" lineHeight="150%">
        Trusted by Industry Leaders{' '}
      </x.h1>
      <Carousel items={items} visibleItems={isUp ? 6 : 2} />
    </x.section>
  )
}
