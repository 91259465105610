import React from 'react'
import { x } from '@xstyled/styled-components'
import chainlink from '@pool/assets/logos/chainlink.svg'
import { Button } from '@pool/components/button/Button'

const Graphic = () => {
  return (
    <svg
      width="647"
      height="599"
      viewBox="0 0 647 599"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.25">
        <g clipPath="url(#clip0_1724_108)">
          <path
            d="M-16.7269 37.1964L-119.114 95.4528L-22.4842 265.822L79.9024 207.566L-16.7269 37.1964Z"
            fill="#093331"
          />
          <path
            d="M179.343 0.654787L62.1506 12.1639L81.2407 207.169L198.433 195.66L179.343 0.654787Z"
            fill="#093331"
          />
          <path
            d="M373.302 47.1554L261.568 9.92725L199.818 195.851L311.552 233.079L373.302 47.1554Z"
            fill="#093331"
          />
          <path
            d="M531.676 168.64L444.72 89.112L312.807 233.805L399.763 313.334L531.676 168.64Z"
            fill="#093331"
          />
          <path
            d="M626.965 344.074L579.822 235.997L400.555 314.441L447.698 422.519L626.965 344.074Z"
            fill="#093331"
          />
          <path
            d="M642.753 543.23L643.575 425.291L447.95 423.923L447.128 541.862L642.753 543.23Z"
            fill="#093331"
          />
          <path
            d="M576.349 731.609L624.994 624.201L446.838 543.257L398.193 650.665L576.349 731.609Z"
            fill="#093331"
          />
          <path
            d="M-120.776 439.732L-120.436 524.587L15.0651 524.043L14.7251 439.188L-120.776 439.732Z"
            fill="#093331"
          />
          <path
            d="M-41.5878 278.072L-91.1099 346.921L18.8327 426.253L68.3547 357.404L-41.5878 278.072Z"
            fill="#093331"
          />
          <path
            d="M117.315 193.894L36.8462 220.439L79.2357 349.346L159.704 322.8L117.315 193.894Z"
            fill="#093331"
          />
          <path
            d="M295.294 219.346L214.616 193.449L173.261 322.692L253.939 348.589L295.294 219.346Z"
            fill="#093331"
          />
          <path
            d="M424.318 344.77L374.246 276.322L264.943 356.535L315.015 424.984L424.318 344.77Z"
            fill="#093331"
          />
          <path
            d="M455.095 522.16L454.755 437.305L319.254 437.849L319.594 522.704L455.095 522.16Z"
            fill="#093331"
          />
          <path
            d="M375.909 683.806L425.431 614.957L315.489 535.625L265.967 604.475L375.909 683.806Z"
            fill="#093331"
          />
          <path
            d="M-89.9368 617.097L-39.8645 685.546L69.4385 605.333L19.3663 536.884L-89.9368 617.097Z"
            fill="#093331"
          />
          <path
            d="M12.1677 453.394L12.853 512.089L107.202 510.984L106.517 452.289L12.1677 453.394Z"
            fill="#093331"
          />
          <path
            d="M113.465 332.749L63.0574 362.691L111.187 443.977L161.595 414.035L113.465 332.749Z"
            fill="#093331"
          />
          <path
            d="M268.475 360.26L217.382 331.507L171.163 413.899L222.256 442.651L268.475 360.26Z"
            fill="#093331"
          />
          <path
            d="M322.155 508.476L321.469 449.781L227.12 450.886L227.806 509.581L322.155 508.476Z"
            fill="#093331"
          />
          <path
            d="M220.852 629.152L271.26 599.21L223.13 517.924L172.722 547.865L220.852 629.152Z"
            fill="#093331"
          />
          <path
            d="M65.8578 601.613L116.951 630.366L163.17 547.974L112.077 519.222L65.8578 601.613Z"
            fill="#093331"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1724_108">
          <rect
            width="960.766"
            height="959.238"
            fill="white"
            transform="translate(646.784 0.54071) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function Provider() {
  return (
    <x.section
      id="b2b"
      mt="200px"
      w={{ _: '100%', maxWidth: 'midWidth' }}
      mx="auto"
      px={{ _: '30px', md: '60px' }}>
      <x.div
        bg="blackGreen"
        display="flex"
        p={{ _: '30px', xl: '130px' }}
        borderRadius="5px"
        position="relative"
        justifyContent="flex-end">
        <x.div
          position="absolute"
          left="0"
          top="20px"
          display={{ _: 'none', xl: 'block' }}>
          <Graphic />
        </x.div>
        <x.div>
          <x.img
            src={chainlink}
            color="white"
            mb="40px"
            w={{ _: '120px', sm: 'auto' }}
          />
          <x.h2
            fontSize="30px"
            fontWeight="600"
            color="lime"
            lineHeight="150%"
            mb="30px"
            maxWidth="400px">
            Largest Oracle <x.span color="lightGreen"> Provider</x.span>
          </x.h2>
          <x.p
            maxWidth="560px"
            fontSize="16px"
            color="lime"
            lineHeight="200%"
            mb="30px">
            Chainlink is the largest and most widely used decentralised oracle,
            powering DeFi since 2019. LinkPool has been on the forefront of the
            Chainlink Network since its inception in late 2017.
          </x.p>
          {/* <Button variant="vandog" size="md" fontWeight="600">
            Read More
          </Button> */}
        </x.div>
      </x.div>
    </x.section>
  )
}
