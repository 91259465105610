import React from 'react'
import { x } from '@xstyled/styled-components'
import { Chessboard, getConfig } from '@pool/components/chessboard/Chessboard'
import { MovingCard } from './MovingCard'

const CardOne = () => {
  return (
    <x.article bg="lime" display="flex" h="100%" p="38px">
      <x.div mt={{ _: '0', xl: 'auto' }}>
        <x.h1
          mb="10px"
          fontSize="34px"
          fontWeight="600"
          lineHeight="110%"
          color="blackGreen">
          Powerful Infrastructure
        </x.h1>
        <x.p fontSize="16px" fontWeight="400" color="blackGreen">
          Unmatched Performance
        </x.p>
      </x.div>
    </x.article>
  )
}

// function createAndModifyGrid() {
//   const grid = []
//   for (let x = 0; x < 19; x++) {
//     for (let y = 0; y < 13; y++) {
//       grid.push({ x, y })
//     }
//   }

//   const elementsToRemove = Math.floor(grid.length * 0.1)

//   for (let i = 0; i < elementsToRemove; i++) {
//     const randomIndex = Math.floor(Math.random() * grid.length)
//     grid.splice(randomIndex, 1)
//   }

//   return grid
// }

const CardThree = () => {
  return (
    <x.div>
      <x.article bg="lime" display="flex" h={{ _: '50%', xl: '35%' }} p="38px">
        <x.div mt="auto">
          <x.p fontSize="16px" fontWeight="400" color="blackGreen" mb="10px">
            CPU
          </x.p>
          <x.h1
            fontSize="55px"
            fontWeight="600"
            lineHeight="110%"
            color="blackGreen">
            1500
          </x.h1>
        </x.div>
      </x.article>
      <x.article
        bg="blackGreen"
        display="flex"
        h={{ _: '50%', xl: '65%' }}
        p="38px">
        <x.div mt="auto">
          <x.p fontSize="16px" fontWeight="400" color="lime" mb="10px">
            RAM
          </x.p>
          <x.h1 fontSize="55px" fontWeight="600" lineHeight="110%" color="lime">
            7 TB
          </x.h1>
        </x.div>
      </x.article>
    </x.div>
  )
}

const CardFour = () => {
  const config = getConfig({
    cell_height: 150,
    cell_width: 89,
    cell_amount_x: 4,
    cell_amount_y: 4,
    cell_color: '#041615',
    stroke_width: 0,
    axis_x: false,
    axis_y: false
  })

  const coordinates = [
    { x: 0, y: 0 },
    { x: 2, y: 0 },
    { x: 1, y: 1 },
    { x: 3, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 3 }
  ]

  return (
    <x.article
      bg="grassGreen"
      display="flex"
      h={{ _: '400px', md: '100%' }}
      p="38px"
      position="relative">
      <x.div position="absolute" top="0" left="0" w="100%" h="100%">
        <Chessboard
          config={config}
          coordinates={coordinates}
          width="100%"
          height="100%"
          preserveAspectRatio="none"
        />
      </x.div>
      <x.div mt="auto" zIndex="1" position="relative">
        <x.p fontSize="16px" fontWeight="400" color="lime" mb="10px">
          Networking
        </x.p>
        <x.h1 fontSize="55px" fontWeight="600" lineHeight="110%" color="lime">
          100 Gbps
        </x.h1>
      </x.div>
    </x.article>
  )
}

export function Specs() {
  return (
    <x.section
      display="flex"
      justifyContent="center"
      id="infra"
      mt={{ _: '75px', xl: '130px' }}
      pt={{ _: '75px', xl: '130px' }}
      w={{ _: '100%', maxWidth: 'maxWidth' }}
      mx="auto"
      px={{ _: '30px', md: '60px' }}>
      <x.div
        w="1426px"
        display="grid"
        gap="0"
        gridTemplateColumns={{ _: 1, md: 2, xl: 4 }}
        h={{ _: 'auto', md: 'auto', xl: '580px' }}>
        <CardOne />
        <MovingCard />
        <CardThree />
        <CardFour />
      </x.div>
    </x.section>
  )
}
