import React from 'react'
import { x } from '@xstyled/styled-components'
import { ContactForm } from './ContactForm'
import { FooterChess } from './FooterChess'

export function Footer() {
  return (
    <x.div>
      <x.footer
        pt={{ _: '75px', xl: '130px' }}
        pb="85px"
        bg="blackGreen"
        id="footer">
        <x.div
          px={{ _: '30px', md: '60px' }}
          w={{ _: '100%', maxWidth: 'midWidth' }}
          mx="auto">
          <x.h1
            mb="60px"
            maxWidth="600px"
            fontSize={{ _: '20px', xl: '30px' }}
            fontWeight="600"
            lineHeight="150%"
            color="lime">
            Find Out How We Can <x.span color="lightGreen">Help</x.span>
          </x.h1>

          <x.div w={{ _: '100%', lg: '940px' }} mr={{ _: '0px', lg: '300px' }}>
            <ContactForm />
          </x.div>
          <x.div display="flex" spaceX="20px" my="40px">
            <x.a
              color="lime"
              href="https://x.com/linkpoolio"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="X LinkPool"
              name="X LinkPool"
              textDecoration="none">
              <svg
                width="20px"
                height="20px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 300.251">
                <x.path
                  fill="lime"
                  d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
                />
              </svg>
            </x.a>
            <x.a
              color="lime"
              href="https://www.linkedin.com/company/linkpool/"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="Linkedin LinkPool"
              name="Linkedin LinkPool"
              textDecoration="none">
              LinkedIn
            </x.a>

            <x.a
              color="lime"
              href="https://t.me/linkpoolio"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="Telegram LinkPool"
              name="Telegram LinkPool"
              textDecoration="none">
              Telegram
            </x.a>
          </x.div>
        </x.div>
      </x.footer>
      <FooterChess />
    </x.div>
  )
}
